<template>
  <transition name="modal">
    <div class="modal-mask" @click="closeModal" style="overflow-y: scroll;">
      <div class="modal-container" @click.stop>
        <div class="close-div">
          <img @click="closeModal" style="cursor:pointer;width: 24px; height: 24px;" :src="$root.CDN_SITE + 'icon/ic_close_black.svg'">
        </div>
        <div class="delete-account-top">
          <h4 class="modal-header">Hesabımı <span>Askıya Al</span></h4>
          <div class="user-avatar">
            <img :src="user.avatar_url" alt="user avatar" class="user-avatar-img">
          </div>
          <p class="info-text">Sevgili <span style="text-transform: capitalize;">{{user.firstname}}</span>, hesabını askıya almak üzeresin.</p>
          <p class="info-description">Fikirlerin bizim için değerli. Hesabını askıya alma sebebini birkaç cümleyle açıklarsan, bu görüşlerin Bionluk’u senin için daha iyi bir platform haline getirmemiz adına bize yol gösterici olacaktır. Zaman ayırdığın için teşekkürler, yeniden görüşmek dileğiyle...</p>
          <custom-input inputType="textArea" v-model="description" style="margin-top: 40px; width: 100%; height: 200px;" inputStyle="background-color: #fefdfd;"/>
        </div>
        <div class="seperator" />
        <div class="delete-account-bottom">
          <custom-button buttonType="border" style="width: 120px;" :disabled="disableButton" @click="closeModal">İptal</custom-button>
          <custom-button buttonType="dark" style="width: 200px; margin-left: 30px;" :disabled="disableButton" @click="holdAccount">Devam Et</custom-button>
        </div>
      </div>
      </div>
  </transition>
</template>

<script>
  //import LogRocket from 'logrocket';
  import Api from '@/utils/request'
  import CustomInput from "../../components/CustomInput";
  import CustomButton from "../../components/CustomButton";

  export default {
    name: "src-pages-modals-closeAccount-v1",
    components: {
      CustomButton,
      CustomInput,
    },
    data () {
      return {
        description: null,
        disableButton: false
      }
    },
    methods: {
      closeModal() {
        this.$store.commit(this.types.DISABLE_MODAL);
      },
      holdAccount() {
        this.disableButton = true
        Api.post('users/hold', { description: this.description })
          .then(() => {
            amplitude.regenerateDeviceId()
            amplitude.setUserId(null)
            localStorage.removeItem("token")
            localStorage.removeItem("visitor_id")
            window.location.href = "/"
          })
          .catch(err => {
            if (err.status === 422) {
              this.$toasted.global.errorToast({ description: err.data.message })
            } else {
              this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE})
            }
          })
          .finally(() => {
            this.disableButton = false
          })
      }
    }
  }

</script>

<style scoped lang="scss">
  .modal-mask {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    background-color: rgba(0, 0, 0, .8);
  }


  .modal-container {
    width: calc(100vw - 20px) !important;
    max-height: 685px;
    max-width: 770px;
    overflow-y: scroll;

    background-color: #ffffff;
    box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px #ffffff;
    padding: 50px 0 30px 0;
    position: relative;
  }

  .close-div {
    z-index: 1;
    position: absolute;
    top: 20px;
    right: 20px;
    opacity: 0.75;
  }

  .close-div:hover {
    opacity: 1;
  }

  .delete-account-top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 85px;
    padding-right: 85px;
  }

  .modal-header {
    font-size: 32px;
    color: #2d3640;
    font-weight: 400;

    span {
      font-weight: 600;
    }
  }

  .user-avatar {
    width: 84px;
    height: 84px;
    position: relative;
    margin-top: 11px;
  }

  .user-avatar-img {
    box-sizing: border-box;
    border: 2px solid #eaeaea;
    border-radius: 100%;
    max-width: calc(100% - 2px);
    height: auto;
  }

  .info-text {
    font-size: 16px;
    color: #2d3640;
    font-weight: 800;
    margin-top: 12px;
  }

  .info-description {
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #5e6b79;
    margin-top: 5px;
  }


  .seperator {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;
    border-bottom: 1px solid #bfc8d2;
  }

  .delete-account-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
